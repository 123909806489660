import React from "react"

import Layout from "../layouts/default"
import Seo from "../components/seo"

import PageTitle from "../components/page-title"
import Heading from "../components/heading"
import Text from "../components/text"
import TextLink from "../components/textlink"

import Grid from "../components/grid"
import Stack from "../components/stack"
import Breadcrumb from "../components/breadcrumb"

const ImpressumPage = () => (
  <Layout backdrop="aussen">
    <Seo title="Impressum" />
    <Stack space={12}>
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Impressum",
              link: "/impressum",
            },
          ]}
        />
        <PageTitle>Impressum</PageTitle>
      </Stack>
      <Stack space={12} dividers>
        <Grid columns={[1, 2]}>
          <Heading level={5}>Universitätsstadt Tübingen</Heading>
          <Stack space={3}>
            <Text>Körperschaft des öffentlichen Rechts</Text>
            <Text>Vertretungsberechtigt: Oberbürgermeister Boris Palmer</Text>
          </Stack>
        </Grid>
        <Grid columns={[1, 2]}>
          <Heading level={5}>Hausanschrift</Heading>
          <Stack space={3}>
            <Text>
              Am Markt 1 <br />
              72070 Tübingen
            </Text>
          </Stack>
        </Grid>
        <Grid columns={[1, 2]}>
          <Heading level={5}>Verantwortlich für den Inhalt</Heading>
          <Stack space={3}>
            <Text as="div">
              <Text sans bold>
                Museum Hölderlinturm
              </Text>
              Bursagasse 6 <br />
              72070 Tübingen
            </Text>
            <Text>Leitender Kurator: Florian Mittelhammer</Text>
            <TextLink href="mailto: florian.mittelhammer@tuebingen.de">
              florian.mittelhammer@tuebingen.de
            </TextLink>
          </Stack>
        </Grid>
      </Stack>
      <Stack space={3}>
        <Heading level={5}>Urheberrechte</Heading>
        <Text>
          Das Layout der Website, die verwendeten Grafiken sowie die Texte sind
          urheberrechtlich geschützt. Die Rechte an den auf dieser Website
          bereitgestellten Inhalten und Werken liegen ausschließlich bei der
          Universitätsstadt Tübingen. Unveränderte Auszüge oder Inhalte aus
          dieser Website können für persönliche, private und nicht kommerzielle
          Zwecke ausgedruckt oder auf sonstige Weise vorübergehend gespeichert
          werden, sofern hierbei sämtliche Urheberrechts- und Eigentumshinweise
          unverändert übernommen werden. Davon abgesehen darf diese Website
          weder ganz noch auszugsweise ohne die vorherige schriftliche
          Zustimmung von der Universitätsstadt Tübingen kopiert, modifiziert
          oder auf Medien jedweder Art öffentlich dargeboten oder neu
          veröffentlicht werden; insbesondere in Frames oder iFrames.
        </Text>
      </Stack>
    </Stack>
  </Layout>
)

export default ImpressumPage
